p, h6 ,li, span {
font-family: 'Inconsolata', monospace;
}

li{
    font-weight: bold;
    font-size: 1.1rem;
}

.green{
    color: rgb(74, 216, 180);
    font-weight: bold;
    margin-left: 2px;
}

.pink {
    color: rgb(184, 64, 206);
    font-weight: bold;
}   

.yellow {
    color: rgb(255, 230, 0);
    font-weight: bold;
}

.orange {
    color: rgb(255, 146, 4);
    font-weight: bold;
}
.blue{
    color: rgb(4, 255, 255);
    font-weight: bold;
}

.besinci{
    margin-top: 56px;
}